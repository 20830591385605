import * as t from './quick.types';

const INITIAL_STATE = {
    school: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case t.GET_QUICK_RESULT_SUCCESS:
            // document.title = `${action.payload?.schoolName} - Official Athletics Website`
            return {
                ...state, school: action.payload
            }
        case t.GET_QUICK_RESULT_ERROR:
            return {
                ...state, school: null
            };
        default: return state;
    }
};

export default reducer;