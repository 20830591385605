import { all, takeEvery, call, put } from 'redux-saga/effects'
import * as actionsTypes from "./quick.types";
import * as actions from "./quick.actions";
import { apiService } from "../../services/api";

export function* getQuick(action) {
	yield takeEvery(actionsTypes.GET_QUICK, function* (action) {
		try {
			const getQuickResult = yield call(apiService.getQuick, action.payload);
			if(getQuickResult.school) {
				yield put(actions.getQuickResultSuccess(getQuickResult.school));
			} else {
				yield put(actions.getQuickResultError(getQuickResult));
			}
		} catch (error) {
			yield put(actions.getQuickResultError("error", error));
		}
	});
}

export default function* rootSaga() {
  yield all([
	getQuick()
  ]);
}