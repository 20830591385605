import * as t from './activities.types';

export const getActivities = (sportSegment, genderSegment, levelSegment, year) => ({
    type: t.GET_ACTIVITIES,
    payload: {
        sportSegment, genderSegment, levelSegment, year
    }
});

export const getActivitiesResultSuccess = (response) => ({
    type: t.GET_ACTIVITIES_RESULT_SUCCESS,
    payload: response
});

export const getActivitiesResultError = (response) => ({
    type: t.GET_ACTIVITIES_RESULT_ERROR,
    payload: response.message
});


export const getSchedules = (sportSegment, genderSegment, levelSegment, year) => ({
    type: t.GET_SCHEDULES,
    payload: {
        sportSegment, genderSegment, levelSegment, year
    }
});

export const getSchedulesResultSuccess = (response) => ({
    type: t.GET_SCHEDULES_RESULT_SUCCESS,
    payload: response
});

export const getSchedulesResultError = (response) => ({
    type: t.GET_SCHEDULES_RESULT_ERROR,
    payload: response.message
});


export const getRosters = (sportSegment, genderSegment, levelSegment, year, fromHome) => ({
    type: t.GET_ROSTERS,
    payload: {
        sportSegment, genderSegment, levelSegment, year, fromHome
    }
});

export const getRostersResultSuccess = (response, fromHome) => ({
    type: t.GET_ROSTERS_RESULT_SUCCESS,
    payload: {
        response, fromHome
    }
});

export const getRostersResultError = (response) => ({
    type: t.GET_ROSTERS_RESULT_ERROR,
    payload: response.message
});


export const getNews = (sportSegment, genderSegment, levelSegment) => ({
    type: t.GET_NEWS,
    payload: {
        sportSegment, genderSegment, levelSegment
    }
});

export const getNewsResultSuccess = (response) => ({
    type: t.GET_NEWS_RESULT_SUCCESS,
    payload: response
});

export const getNewsResultError = (response) => ({
    type: t.GET_NEWS_RESULT_ERROR,
    payload: response.message
});


export const getGallery = (sportSegment, genderSegment, levelSegment) => ({
    type: t.GET_GALLERY,
    payload: {
        sportSegment, genderSegment, levelSegment
    }
});

export const getGalleryResultSuccess = (response) => ({
    type: t.GET_GALLERY_RESULT_SUCCESS,
    payload: response
});

export const getGalleryResultError = (response) => ({
    type: t.GET_GALLERY_RESULT_ERROR,
    payload: response.message
});