const levels = [
	'ALL', 
	'V', 
	'JV', 
	'S', 
	'SA', 
	'SB', 
	'F', 
	'FA', 
	'FB', 
	'MS', 
	'8th', 
	'7th', 
	'8TH', 
	'7TH', 
	'8', 
	'7'
]

function trG(genderSegment) {
	if(genderSegment) {
		return genderSegment.toLowerCase() === 'b' ? 'Boys' : (genderSegment.toLowerCase() === 'g' ? 'Girls' : 'Coed');
	} else {
		return '';
	}
}

function toProperCase(stringToConvert) {
	let firstChar = stringToConvert.substring(0,1);
	var remainder = stringToConvert.substring(1);

	return firstChar.toUpperCase() + remainder;
}

function trGOne(genderSegment) {
	if(genderSegment) {
		return genderSegment.toLowerCase() === 'b' ? 'Boy' : (genderSegment.toLowerCase() === 'g' ? 'Girl' : 'Coed');
	} else {
		return '';
	}
}
function trPlace(place) {
	return place.toLowerCase() === 'h' ? 'Home' : 'AWAY';
}

function toFeet(n) {
	if(n.indexOf("'") !== -1 || n.indexOf('"') !== -1) {
		return n;
	} else {
		let realFeet = (n / 12),
			feet = Math.floor(realFeet),
			inches = Math.round((realFeet - feet) * 12);
		return feet + "&prime;" + inches + '&Prime;';
	}
}

function toFeetJS(n) {
	if(n.indexOf("'") !== -1 || n.indexOf('"') !== -1) {
		return n.replace('"', "″").replace("'", "′");
	} else {
		let realFeet = (n / 12),
			feet = Math.floor(realFeet),
			inches = Math.round((realFeet - feet) * 12);  
		return `${feet}′${inches}″`;
	}
}

function trEventType(eventType) {
	switch(eventType.toLowerCase()) {
		case('g'):
			return 'Game';
		case('t'):
			return 'Tournament';
		case('e'):
			return 'Event';
		case('s'):
			return 'Scrimmage';
		case('n'):
			return 'Non-Compete';
		case('p'):
			return 'Practice';
		case('m'):
			return 'Meeting';
		case('o'):
			return 'Other';
		default:
		return '-';
	}
}

function orderLevels(array_to_order) {
	var ordered_array = [], 
			len = array_to_order.length,
			index, current;

	for (; len--;) {
			current = array_to_order[len];
			index = levels.indexOf(current.value);
			ordered_array[index] = current;
	}

	return ordered_array;
}

function advancedOrderLevels(array_to_order, order_by) {
	var ordered_array = [], 
			len = array_to_order.length,
			index, current;

	for (; len--;) {
			current = array_to_order[len];
			index = levels.indexOf(current[order_by]);
			ordered_array[index] = current;
	}

	return ordered_array;
}

function getEventAndResultsClass(event) {
	switch(true) {
		case event.gs === 'P':
			return 'p';
		case event.gs !== 'G':
			return 'm';
		case event.place === 'A':
			return 'a';
		case event.place === 'H':
			return 'h';
		case event.gs !== 'P':
			return 'm';
		default:
			return 'm';
	}
}

function trEventActivity(eventActivity) {
	switch(eventActivity.toLowerCase()) {
		case('rescheduled -  '):
		case('rescheduled'):
			return 'Rescheduled';
		case('postponed'):
			return 'Postponed';
		case('kissimee klassic'):
			return 'Kissimee Klassic';
		case('tri w/ dgn, york'):
			return 'Tri w/ Dgn, York';
		case('tiger classic'):
			return 'Tiger Classic';
		case('invite'):
			return 'Invite';
		case('canino invite'):
			return 'Canino Invite';
		case('cancelled'):
			return 'Cancelled';
		case('quad city invite overnite'):
			return 'Quad City Invite Overnite';
		case('senior night'):
			return 'Senior Night';
		case('hospitlaity'):
			return 'Hospitlaity';
		case('team invite'):
			return 'Team Invite';
		default:
			return '-';
	}
}
function trEventActivityClass(eventActivity) {
	switch(eventActivity.toLowerCase()) {
		case('cancelled'):
			return 'red';
		case('rescheduled -  '):
		case('rescheduled'):
		case('postponed'):
		case('kissimee klassic'):
		case('tri w/ dgn, york'):
		case('tiger classic'):
		case('invite'):
		case('canino invite'):
		case('quad city invite overnite'):
		case('senior night'):
		case('hospitlaity'):
		case('team invite'):
			return 'yellow';
		default:
			return '';
	}
}
function checkActivity(activity) {
	return (activity && activity.length !== 0 && activity !== " " && trEventActivityClass(activity).length !== 0) ? 'has-activity' : '';
}


export { 
	trG, 
	trGOne, 
	trPlace, 
	trEventType, 
	toFeet, 
	toFeetJS, 
	trEventActivity, 
	trEventActivityClass, 
	checkActivity,
	getEventAndResultsClass,
	toProperCase,
	orderLevels,
	advancedOrderLevels
};