import React from 'react';

import {
  withRouter
} from "react-router-dom";
import { connect } from "react-redux";

import {
  getActivities,
  getRosters
} from "../../app/activities/activities.actions";

import {
  trG
} from '../../app/common';

import {
  specifyUrl
} from "../../app/menu/menu.actions";

import { urlParamToTitle } from '../../helpers';

import {
  specifyTitle
} from '../../app/header/header.actions';
import "./roster.scss";
import printerButton from '../../assets/button-print.png';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

const getLevelName = (levels, levelSegment) => {
  if (levels.length > 0) {
    return levels.filter((l) => l.shortLevel === levelSegment)[0].levelName;
  }
  return '';
};

const getStateId = () =>{
    if(isLocalhost) {
        return (process.env.REACT_APP_DEFAULT_STATE) ? process.env.REACT_APP_DEFAULT_STATE : 'il';
    }
    return window.location.hostname.split('.')[0];
}

const rosterList = (data, year, themePrimaryColor) => {
  let atheletes = []
  data.roster.map((athelete, idx) => {
    return atheletes.push(rosterItem(athelete));
  });

  const table = <div className="event-table">
    <table>
      <tbody>
        <tr id="first-header">
          <th colSpan="6">{year} {data.level} Roster</th>
        </tr>
        <tr id="second-header" style={{ backgroundColor: themePrimaryColor}}>
          <th className="col-1">Jersey</th>
          <th className="col-2">Name</th>
          <th className="col-3">Position</th>
          <th className="col-4">Height</th>
          <th className="col-5">Weight</th>
          <th className="col-6 last">className</th>
        </tr>
        {atheletes}
      </tbody>
    </table>
    <br/>
  </div>

  return table;
}

const rosterItem = (data) => {
  return <tr>
    <td className="col-1">{data.jersey}</td>
    <td className="col-2">{data.fName} {data.lName}</td>
    <td className="col-3">{data.position}</td>
    <td className="col-4">{data.height}"</td>
    <td className="col-5">{data.weight}</td>
    <td className="col-6">{data.gradYear}</td>
  </tr>
}

class PrintPage extends React.Component {

  constructor(props) {
    super(props);

    const schoolId = window.location.pathname.split('/')[1]
    const state = getStateId();

    if(this.props.urlSchool !== schoolId) {
      this.props.specifyUrl(state, schoolId);
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 10);
  }

  getData() {
    const { sportSegment, genderSegment, levelSegment} = this.props.match.params;
    const { specifyTitle, getRosters, year, rosterYear, getActivities } = this.props;

    specifyTitle(trG(genderSegment) + ' - ' + sportSegment);
    getRosters(sportSegment, genderSegment, levelSegment, rosterYear);
    getActivities(sportSegment, genderSegment, levelSegment, year);
  }

  componentDidUpdate(prevProps){
    if(this.props.location.pathname !== prevProps.location.pathname) {
        let splitPath = this.props.location.pathname.split('/');

        this.props.getRosters(splitPath[3], splitPath[4], splitPath[5], this.props.rosterYear);
        this.props.getActivities(splitPath[3], splitPath[4], splitPath[5], this.props.year);
        this.props.specifyTitle(trG(splitPath[4]) + ' - ' + splitPath[3])
    }
  }

  render() {
    return (
      <div id="print-roster">
        <div id="wrapper">
          <div id="header" className="print">
            <div id="avatar"><img width="50" height="50" alt="" src={this.props.bannerImageUrl} title="Mustangs"/></div>
            <div id="teamname">
                <p className="name1">{this.props.homeTitle}</p>
            </div>
          </div>

          <div className="panel">
            <h1>
              {trG(this.props.genderSegment)} {urlParamToTitle(this.props.sportSegment)} {getLevelName(this.props.levels, this.props.levelSegment)}
            </h1>
            <div id="content-bottom">
              <button id="print-button" onClick="() => javascript:window.print()" title="print this page">
                <img src={printerButton} alt="Print" width="87" height="24"/>
              </button>
              
              {(this.props.rosterSections && this.props.rosterSections.length > 0) &&
                this.props.rosterSections.map((rosterSection, idx) => {
                  return rosterList(rosterSection, this.props.year, this.props.themePrimaryColor)
                })}

              <div className="section-2">
                Coaching Staff
              </div>
              <div className="section-3">
                <table className="coaching-Staff">
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.main.error,
		urlSchool: state.menu.urlSchool,
    schoolName: state.header.homeTitle,
    sportSegment: state.activities.sportSegment,
    genderSegment: state.activities.genderSegment,
    levelSegment: state.activities.levelSegment,
    levels: state.menu.levelDictionary,
    year: state.activities.year,
    years: state.activities.years,
    rosterSections: state.activities.rosterSections,
    activitiesList: state.activities.activitiesList,
    rosterYear: state.activities.rosterYear,
    rosterSectionsTeam: state.activities.rosterSectionsTeam,
    bannerImageUrl: state.menu.menuData?.menu.header.bannerImageUrl,
    homeTitle: state.header.homeTitle,
    themePrimaryColor: state.menu.menuData?.menu.themePrimaryColor,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    specifyTitle: (title) => dispatch(specifyTitle(title)),
    specifyUrl: (state, school) => dispatch(specifyUrl(state, school)),
    getActivities: (sportSegment, genderSegment, levelSegment, year) => dispatch(getActivities(sportSegment, genderSegment, levelSegment, year)),
    getRosters: (sportSegment, genderSegment, levelSegment, year) => dispatch(getRosters(sportSegment, genderSegment, levelSegment, year))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintPage));