import * as t from './main.types';

export const toggleMenuDrawerStatus = () => ({
    type: t.MENU_DRAWER_STATUS
});

export const getSchools = (state) => ({
    type: t.GET_SCHOOLS,
    payload: {
    	state
    }
});

export const getSchoolsResultSuccess = (response) => ({
    type: t.GET_SCHOOLS_RESULT_SUCCESS,
    payload: response
});

export const getSchoolsResultError = (type, message) => ({
    type: t.GET_SCHOOLS_RESULT_ERROR,
    payload: {
        type: type,
        message: message
    }
});