import * as t from './menu.types';
import { trGOne } from '../common';
import { isColorLight } from '../../helpers';
import interstitialAds from '../../data/interstialAds';
import _ from 'lodash';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const getStateId = () =>{
    if(isLocalhost) {
        return (process.env.REACT_APP_DEFAULT_STATE) ? process.env.REACT_APP_DEFAULT_STATE : 'il';
    }
    return window.location.hostname.split('.')[0];
}

const INITIAL_STATE = {
    menuState: window.innerWidth < 1024 ? 'closed' : 'opened',
    menuStateInner: 'closed',
    menuStateInnerInner: 'closed',
    menuStateInnerInnerInner: 'closed',
    innerMenuType: '',
    innerMenuTitle: '',
    innerInnerMenuType: '',
    innerInnerMenuTitle: '',
    innerInnerInnerMenuType: '',
    innerInnerInnerMenuTitle: '',
    innerInnerInnerMenuIdx: '',
    urlState: getStateId(),
    urlSchool: '',
    adSchool: true,
    menuData: null,
    schoolName: '',
    schoolLogo: '',
    state: null,
    city: null,
    zipcode: null,
    primaryColor: '#000000',
    secondaryColor: '#333333',
    year: new Date().getFullYear(),
    allSportsActivitiesOptions: [],
    allTeamsGroupsOptions: [],
    levelDictionary: [],
    staticPages: {},
    newDetailData: null,
    urgentAnnouncements: [],
    activeUrgentAnnouncement: null,
    viewedUrgentAnnouncements: true,
    interstitialAds: [],
    eventDirectionsData: null,
    notFoundError: false,
    athletics: [],
    activities: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case t.SWITCH_MENU:
            let newState = state.menuState === 'closed' ? 'opened': 'closed',
                body = document.body;
            if(newState === 'opened') {
                body.classList.add("menu-opened");
            } else {
                body.classList.remove("menu-opened");
            }
            return {
                ...state,
                menuState: newState,
                /*menuStateInner: 'closed',
                menuStateInnerInner: 'closed',
                menuStateInnerInnerInner: 'closed',*/
            };
        case t.CLOSE_INNER_MENU:
            return {
                ...state,
                menuStateInner: 'closed',
                menuStateInnerInner: 'closed',
                menuStateInnerInnerInner: 'closed',
            };
        case t.CLOSE_INNER_INNER_MENU:
            return {
                ...state,
                menuStateInnerInner: 'closed',
                menuStateInnerInnerInner: 'closed',
                menuStateInner: 'opened'
            };
        case t.CLOSE_INNER_INNER_INNER_MENU:
            return {
                ...state,
                menuStateInnerInner: 'opened',
                menuStateInnerInnerInner: 'closed',
                menuStateInner: 'closed'
            };
        case t.OPEN_INNER_MENU:
            return {
                ...state,
                menuStateInner: 'opened',
                innerMenuType: action.payload.innerMenuType,
                innerMenuTitle: action.payload.innerMenuTitle
            };
        case t.OPEN_INNER_INNER_MENU:
            return {
                ...state,
                menuStateInner: 'closed',
                menuStateInnerInner: 'opened',
                innerInnerMenuType: action.payload.innerMenuType,
                innerInnerMenuTitle: action.payload.innerMenuTitle
            };
        case t.OPEN_INNER_INNER_INNER_MENU:
            return {
                ...state,
                menuStateInner: 'closed',
                menuStateInnerInner: 'closed',
                menuStateInnerInnerInner: 'opened',
                innerInnerInnerMenuType: action.payload.innerMenuType,
                innerInnerInnerMenuTitle: action.payload.innerMenuTitle,
                innerInnerInnerMenuIdx: action.payload.idx
            };
        case t.SPECIFY_URL:
            return {
                ...state, urlSchool: action.payload.school
            };
        case t.SET_VIEWED_URGENT_ANNOUNCEMENTS:
            sessionStorage.setItem('viewedUrgentAnnouncements', 1);
            return {
                ...state,
                viewedUrgentAnnouncements: true
            }
        case t.CLOSE_URGENT:
            return {
                ...state
            };
        case t.GET_MENU_STATE_SCHOOL_RESULT_SUCCESS: {
            // Set the title to the name of the school
            document.title = `${action.payload.menu.school} - Official Athletics Website`;

            const schoolPath = action.payload.menu.schoolPath.toLowerCase();

            // Set theme as light or dark
            document.body.classList.add(`theme-color-${(isColorLight(action.payload.menu.themePrimaryColor)) ? 'light' : 'dark'}`);

            let viewedUrgentAnnouncements = (sessionStorage.getItem('viewedUrgentAnnouncements')) ? true : false;

            let athletics = new Set();
            let allSportsActivitiesOptions = [], levelDictionary = [];

            [
                ...(action.payload.menu.girlsSports ? action.payload.menu.girlsSports : []),
                ...(action.payload.menu.boysSports ? action.payload.menu.boysSports : []),
                ...(action.payload.menu.coedSports ? action.payload.menu.coedSports : [])
            ].forEach((item) => {
                let found = false;
                allSportsActivitiesOptions.forEach((innerItem) => {
                    if(innerItem.sport === item.sport) {
                        found = true;
                    }
                })
                if(!found) {
                    allSportsActivitiesOptions.push(item);
                }
                athletics.add(item.sportSegment);
            });

            let activities = new Set();
            [
                ...(action.payload.menu.girlsActivities ? action.payload.menu.girlsActivities : []),
                ...(action.payload.menu.boysActivities ? action.payload.menu.boysActivities : []),
                ...(action.payload.menu.coedActivities ? action.payload.menu.coedActivities : [])
            ].forEach((item) => {
                let found = false;
                allSportsActivitiesOptions.forEach((innerItem) => {
                    if ( innerItem.sport === item.sport ) {
                        found = true;
                    }
                })
                if ( !found ) {
                    allSportsActivitiesOptions.push(item);
                }
                activities.add(item.sportSegment);
            });

            allSportsActivitiesOptions.sort(function(a, b){
                if(a.sport < b.sport) { return -1; }
                if(a.sport > b.sport) { return 1; }
                return 0;
            });
            
            let allTeamsGroupsOptions = [];
            [
                ...(action.payload.menu.girlsSports ? action.payload.menu.girlsSports : []),
                ...(action.payload.menu.boysSports ? action.payload.menu.boysSports : []),
                ...(action.payload.menu.coedSports ? action.payload.menu.coedSports : [])
            ].forEach((item) => {
                item.levels.forEach((levelItem) => {
                    let foundLevelItem = false;
                    if(levelItem.shortLevel) {
                        levelDictionary.forEach((itemDict) => {
                            if(itemDict.shortLevel === levelItem.shortLevel.toLowerCase()) {
                                foundLevelItem = true;
                            }
                        });
                        if(!foundLevelItem) {
                            levelDictionary.push({
                                shortLevel: levelItem.shortLevel.toLowerCase(),
                                levelName: levelItem.levelName
                            });
                        }
                    }
                    let foundLevel = false;
                    if(levelItem.code.length) {
                        allTeamsGroupsOptions.forEach((levelInnerItem) => {
                            if(
                                levelInnerItem.levelName === levelItem.levelName &&
                                levelInnerItem.gender === levelItem.gender &&
                                levelInnerItem.sport
                            ) {
                                foundLevel = true;
                            }
                        });
                        if(!foundLevel) {
                            allTeamsGroupsOptions.push(
                                Object.assign(
                                    levelItem,
                                    {
                                        gender: item.gender,
                                        sport: item.sport
                                    }
                                )
                            );
                        }
                    }
                });
            });
            allTeamsGroupsOptions = allTeamsGroupsOptions.map((item) => {
                return Object.assign(item, {
                    title: ((item.gender && item.gender !== 'N' ? trGOne(item.gender) + ' ' : '') + (item.levelName ? item.levelName + ' ' : '') + item.sport)
                });
            });
            allTeamsGroupsOptions.sort(function(a, b){
                if(a.title < b.title) { return -1; }
                if(a.title > b.title) { return 1; }
                return 0;
            });
            let fixedMenu = Object.assign({}, action.payload.menu);
            for(let key in fixedMenu) {
                if(
                    key === 'boysActivities' ||
                    key === 'girlsActivities' ||
                    key === 'coedActivities' ||
                    key === 'boysSports' ||
                    key === 'girlsSports' ||
                    key === 'coedSports'
                ) {
                    fixedMenu[key] = fixedMenu[key].filter(item => {
                        let hasChild = false;
                        item.levels.forEach(level => {
                            if(level.levelName) {
                                hasChild = true;
                            }
                        });
                        return hasChild;
                    });
                }
            }
            action.payload.menu = fixedMenu;

            /**
             * Flag urgent announcements within set of announcements
             */
            const urgentIds = action.payload.menu.urgentAnnouncements.map(item => (item.showUrgentUntil) ? item.id : null);
            const announcements = action.payload.announcements.map( function(item) {
                item.isUrgent = (urgentIds.indexOf(item.id) >= 0) ? true : false;
                return item;
            });

            let orderedAnnouncements = _.orderBy(announcements, ['sortVal', 'startDate'], ['asc', 'asc'])

            return {
                ...state,
                schoolName: action.payload.menu.school,
                schoolLogo: `https://8to18-logos.s3.amazonaws.com/images/${state.urlState.toLowerCase()}-${action.payload.menu.schoolPath.toLowerCase()}.png`,
                state: action.payload.menu.state,
                city: action.payload.menu.header.city,
                zipcode: action.payload.menu.header.zip,
                schoolPath: schoolPath,
                primaryColor: action.payload.menu.themePrimaryColor,
                secondaryColor: action.payload.menu.themeSecondarColor,
                adSchool: action.payload.menu.isAdSchool,
                notFoundError: false,
                announcements: orderedAnnouncements,
                urgentAnnouncements: action.payload.menu.urgentAnnouncements,
                viewedUrgentAnnouncements: viewedUrgentAnnouncements,
                interstitialAds: (schoolPath in interstitialAds) ? interstitialAds[schoolPath] : [],
                menuData: action.payload,
                showAd: action.payload.school.showAd,
                levelDictionary: levelDictionary,
                allSportsActivitiesOptions: allSportsActivitiesOptions,
                allTeamsGroupsOptions: allTeamsGroupsOptions,
                activities: [...activities],
                athletics: [...athletics]
            };
        }
        case t.GET_MENU_STATE_SCHOOL_RESULT_ERROR:
            if(action.payload && action.payload.status) {
                return {
                    ...state,
                    notFoundError: action.payload.status
                };    
            } else {
                return {
                    ...state,
                    notFoundError: action.payload
                };
            }
        case t.GET_STATIC_RESULT_SUCCESS:
            let {staticPages} = state;
            staticPages[action.pagename] = action.detail;
            return {
                ...state,
                staticPages: staticPages
            };
        case t.GET_NEW_DETAIL_RESULT_ERROR:
            return {
                ...state, newDetailData: null
            };
        case t.GET_NEW_DETAIL_RESULT_SUCCESS:
            return {
                ...state, newDetailData: action.payload.response
            };
        case t.GET_EVENT_DIRECTIONS_RESULT_ERROR:
            return {
                ...state, eventDirectionsData: null
            };
        case t.GET_EVENT_DIRECTIONS_RESULT_SUCCESS:
            return {
                ...state, eventDirectionsData: action.payload.response
            };
        default: return state;
    }
};

export default reducer;