import * as t from './header.types';
import { trG } from '../common';
import moment from 'moment';

const INITIAL_STATE = {
    title: '',
    subTitle: [],
    back: false,
    printBtn: false,
    backRoute: '',
    homeTitle: ''
};

const reducer = (state = INITIAL_STATE, action) => {
    let title,
        back,
        subTitle = [],
        printBtn;
    switch (action.type) {
        case t.SPECIFY_TITLE:
            
            if (action.payload.title === 'Monthly Calendar') {
                printBtn = true;
            } else {
                printBtn = false;
            }
            // if (document.title !== action.payload.title) {
            //     document.title = action.payload.title;
            // } 
            
            return {
                ...state,
                title: action.payload.title,
                subTitle: [],
                printBtn: printBtn,
                backRoute: '',
                back: false
            };
        case t.GET_ACTIVITIES:
            let backRoute = `activities/${action.payload.sportSegment}/${action.payload.genderSegment}/${action.payload.levelSegment}`;
            return {
                ...state,
                backRoute: backRoute
            };
		case t.GET_ACTIVITIES_RESULT_SUCCESS:
            if(action.payload.sportDetails) {
                subTitle = [action.payload.sportDetails.sportName.toLowerCase(), trG(action.payload.sportDetails.gender) + ' - ' + action.payload.sportDetails.sportName.toLowerCase() + (action.payload.sportDetails.groupVal ? ' ' + action.payload.sportDetails.groupVal.toLowerCase() : '')];
            } else if(action.payload.upcomingEvents.length) {
                subTitle = [action.payload.upcomingEvents[0].sportName.toLowerCase(), trG(action.payload.upcomingEvents[0].gender) + ' - ' + action.payload.upcomingEvents[0].sportName.toLowerCase() + (action.payload.upcomingEvents[0].groupVal ? ' ' + action.payload.upcomingEvents[0].groupVal.toLowerCase() : '')];                
            } else {
                subTitle = [];
            }
            return {
                ...state,
                subTitle: subTitle
            };
        case t.GET_EVENT_DIRECTIONS:
            subTitle = ['Directions', 'Event Locations'];
            return {
                ...state,
                subTitle: subTitle
            };
        case t.GET_STATIC:
            subTitle = ['Information'];
            switch(action.payload) {
                case 'about': 
                    subTitle.push('About Us');
                    break;
                case 'staff': 
                    subTitle.push('Staff');
                    break;
                case 'mission': 
                    subTitle.push('Mission');
                    break;
                case 'sponsors': 
                    subTitle.push('Sponsors');
                    break;
                case 'recruitment': 
                    subTitle.push('Recruitment');
                    break;
                case 'privacy': 
                    subTitle.push('Privacy Policy');
                    break;
                case 'termofuse': 
                    subTitle.push('Terms of Service');
                    break;
                case 'directions': 
                    subTitle = ['Directions', 'School Directions'];
                    break;
                default:
                    subTitle = ['Information', 'Other'];
            }
            return {
                ...state,
                subTitle: subTitle
            };
        case t.GET_EVENT_DETAIL_RESULT_SUCCESS:
            if(action.payload.changeTitle) {
                title = moment(action.payload.response.eventDate).format('MMMM D, YYYY');
                back = true;
            } else {
                back = false;
                title = 'Event Detail';
            }
            return {
                ...state, title: title, back: back
            };
        case t.GET_SCHEDULES_RESULT_SUCCESS:
            title = 'Sсhedule';
            back = true;
            return {
                ...state, title: title, back: back
            };
        case t.GET_ROSTERS_RESULT_SUCCESS:
            if(!action.payload.fromHome) {
                title = 'Team Page';
                back = true;
            } else {
                title = state.title;
                back = state.back;
            }
            return {
                ...state, title: title, back: back
            };
        case t.GET_NEWS_RESULT_SUCCESS:
            title = 'Announcements';
            back = true;
            return {
                ...state, title: title, back: back
            };
        case t.GET_GALLERY_RESULT_SUCCESS:
            title = 'Photos';
            back = true;
            return {
                ...state, title: title, back: back
            };
        case t.GET_MENU_STATE_SCHOOL_RESULT_ERROR:
            return {
                ...state, homeTitle: '8 to 18', title: '8 to 18'
            };
        case t.GET_MENU_STATE_SCHOOL_RESULT_SUCCESS:
            return {
                ...state, homeTitle: action.payload.menu.school
            };
        case t.GET_NEW_DETAIL_RESULT_SUCCESS:
            if(action.payload.changeTitle) {
                title = moment(action.payload.response.startDate).format('MMMM D, YYYY');
                back = true;
            } else {
                back = false;
                title = 'Announcement Detail';
            }
            return {
                ...state, title: title, back: back
            };
        default: return state;
    }
};

export default reducer;