import { all, takeEvery, call, put } from 'redux-saga/effects'
import * as actionsTypes from "./main.types";
import * as actions from "./main.actions";
import { apiService } from "../../services/api";

export function* getSchools(action) {
	yield takeEvery(actionsTypes.GET_SCHOOLS, function* (action) {
		try {
			const getSchoolsResult = yield call(apiService.getSchools, action.payload);
			if(getSchoolsResult.schools) {
				yield put(actions.getSchoolsResultSuccess(getSchoolsResult.schools));
			} else {
				yield put(actions.getSchoolsResultError(getSchoolsResult));
			}
		} catch (error) {
			yield put(actions.getSchoolsResultError("error", error));
		}
	});
}

export default function* rootSaga() {
  yield all([
  	getSchools()
  ]);
}