import * as t from './menu.types';

export const switchMenu = () => ({
    type: t.SWITCH_MENU
});

export const getStatic = (pagename) => ({
    type: t.GET_STATIC,
    payload: pagename
});

export const getStaticResultSuccess = (detail, pagename) => ({
    type: t.GET_STATIC_RESULT_SUCCESS,
    detail: detail,
    pagename: pagename
});

export const getStaticResultError = (type, message) => ({
    type: t.GET_STATIC_RESULT_ERROR,
    payload: {
        type: type,
        message: message
    }
});

export const closeInnerMenu = () => ({
    type: t.CLOSE_INNER_MENU
});

export const closeInnerInnerMenu = () => ({
    type: t.CLOSE_INNER_INNER_MENU
});

export const closeInnerInnerInnerMenu = () => ({
    type: t.CLOSE_INNER_INNER_INNER_MENU
});

export const specifyUrl = (state, school) => ({
    type: t.SPECIFY_URL,
    payload: {
    	state, school
    }
});

export const openInnerMenu = (innerMenuType, innerMenuTitle) => ({
    type: t.OPEN_INNER_MENU,
    payload: {
        innerMenuType, innerMenuTitle
    }
});

export const openInnerInnerMenu = (innerMenuType, innerMenuTitle) => ({
    type: t.OPEN_INNER_INNER_MENU,
    payload: {
        innerMenuType, innerMenuTitle
    }
});

export const openInnerInnerInnerMenu = (innerMenuType, innerMenuTitle, idx) => ({
    type: t.OPEN_INNER_INNER_INNER_MENU,
    payload: {
        innerMenuType, innerMenuTitle, idx
    }
});

export const getMenuStateSchoolResultSuccess = (response) => ({
    type: t.GET_MENU_STATE_SCHOOL_RESULT_SUCCESS,
    payload: response
});

export const getMenuStateSchoolResultError = (payload) => ({
    type: t.GET_MENU_STATE_SCHOOL_RESULT_ERROR,
    payload: payload
});

export const closeUrgent = (shouldClose) => ({
    type: t.CLOSE_URGENT,
    payload: shouldClose
});

export const setViewedUrgentAnnouncements = () => ({
    type: t.SET_VIEWED_URGENT_ANNOUNCEMENTS
});

export const getNewDetail = (newId, changeTitle, subpath) => ({
    type: t.GET_NEW_DETAIL,
    payload: {
        newId, changeTitle, subpath
    }
});

export const getNewDetailResultSuccess = (response, changeTitle) => ({
    type: t.GET_NEW_DETAIL_RESULT_SUCCESS,
    payload: { response, changeTitle }
});

export const getNewDetailResultError = (response) => ({
    type: t.GET_NEW_DETAIL_RESULT_ERROR,
    payload: response.message
});

export const getEventDirections = () => ({
    type: t.GET_EVENT_DIRECTIONS,
});

export const getEventDirectionsResultSuccess = (response) => ({
    type: t.GET_EVENT_DIRECTIONS_RESULT_SUCCESS,
    payload: { response}
});

export const getEventDirectionsResultError = (response) => ({
    type: t.GET_EVENT_DIRECTIONS_RESULT_ERROR,
    payload: response.message
});