import * as t from './quick.types';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const getState = () =>{
    if(isLocalhost) {
        return (process.env.REACT_APP_DEFAULT_STATE) ? process.env.REACT_APP_DEFAULT_STATE : 'il';
    }
    return window.location.hostname.split('.')[0];
}

const getSchool = () =>{
    return window.location.href.split('/')[3];
}
export const getQuick = () => ({
    type: t.GET_QUICK,
    payload: {
        state: getState(),
        school: getSchool()
    }
});

export const getQuickResultSuccess = (payload) => ({
    type: t.GET_QUICK_RESULT_SUCCESS,
    payload: payload
});

export const getQuickResultError = (payload) => ({
    type: t.GET_QUICK_RESULT_ERROR,
    payload: payload
});