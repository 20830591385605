export function clearEmpty(string){
    return (string && string.length > 0) ? string.replace(/↵/gi, '').replace(/<p>&nbsp;<\/p>/gi, '') : '';
}
export function cleanHTML(string) {
    return (string && string.length > 0) ? clearEmpty(string.replace(/(<\/?(?:a)[^>]*>)|<[^>]+>/ig, '$1').replace(/style="[^"]*"/ig, ""))  : '';
}
export function toTitleCase(string) {
  return (string && string.length > 0) ? string.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : '';
};
export function isColorLight(color) {
  let r,g,b = null;
  
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace( 
      color.length < 5 && /./g, '$&$&'
    ));
    r = color >> 16;
    // eslint-disable-next-line
    g = color >> 8 & 255;
    b = color & 255;
  }
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  let hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );
  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return true;
  } else {
    return false;
  }
}
export function isColorDark(color) {
    return !isColorLight(color);
}

export function getEventSportsFilterOptionsFromEvents( events ) {
  let values = {
    optionGroups: {
      'b': {
        label: 'Boys Sports',
        options: []
      },
      'g': {
        label: 'Girls Sports',
        options: []
      },
      'c': {
        label: 'Coed Sports',
        options: []
      }
    },
    total: 0
  }
  events.map( function( event ) {
    const gender = event.gender?.toLowerCase();
    if ( gender && values.optionGroups[gender] 
      && (values.optionGroups[gender].options.length === 0 || !values.optionGroups[gender].options.some( ( i ) => 
        i.label.toLowerCase().trim() === event.sportName.toLowerCase().trim()
      ))) {
        values.optionGroups[gender].options.push( { 
          label: ucwords(event.sportName.trim()), 
          value: `${gender}_${event.sportName.toLowerCase().trim()}` 
      });
      values.total++;
    }
    return values;
  });

  return values;
}

/**
 * 
 * @param {*} events 
 */
export function getEventLevelsFilterOptionsFromEvents( events ) {
  let options = [];
  events.map( ( event ) => {
    if (options.length === 0 || !options.some( ( i ) => 
      i.label.toLowerCase().trim() === event.level1.toLowerCase().trim()
    ) ) {
      options.push( {
        label: event.level1.trim(), 
        value: event.level1.trim() 
      } );
    }
    return options;
  });

  return options;
}

/**
 * 
 * Return an array of label/value objects for inclusion in a drop down filter for events
 * 
 * @param {*} events 
 * @returns 
 */
export function getEventTypesFilterOptionsFromEvents( events ) {
    let availableOptions = [
      {
        label: 'Games',
        value: 'g'
      },
      {
        label: 'Tournaments',
        value: 't'
      },
      {
        label: 'Events',
        value: 'e'
      },
      {
        label: 'Scrimmages',
        value: 's'
      },
      {
        label: 'Non-Competes',
        value: 'n'
      },
      {
        label: 'Practices',
        value: 'p'
      },
      {
        label: 'Meetings',
        value: 'm'
      },
      {
        label: 'Others',
        value: 'o'
      }
    ];
  
  let options = [];

  events.map( function( event ) {
    if (options.indexOf(event.gs) === -1) {
      options.push(event.gs.toLowerCase());
    }
    return options;
  });

  return availableOptions.filter( function( i ) {
    return (options.indexOf(i.value) >= 0);
  });

}

export function getFilteredEvents (
  items,
  filters
) {

  let filteredItems = items;

  if (filters.eventDate) {
    filteredItems = filteredItems.filter((item) => item.eventDate === filters.eventDate);
  }

  if (filters.eventLevel) {
    filteredItems = filteredItems.filter((item) => item.level1?.toLowerCase() === filters.eventLevel?.toLowerCase());
  }

  if (filters.eventSport) {
    filteredItems = filteredItems.filter((item) => (item.gender?.trim() + '_' + item.sportName?.trim()).toLowerCase() === filters.eventSport);
  }

  if (filters.eventType) {
    filteredItems = filteredItems.filter((item) => item.gs?.toLowerCase() === filters.eventType?.toLowerCase());
  }

  if (filters.eventLocation) {
    filteredItems = filteredItems.filter((item) => item.place?.toLowerCase() === filters.eventLocation?.toLowerCase());
  }

  return filteredItems;
}

export function getInitials(string) {
  return string.split(" ").map((n)=>n[0]).join('').toUpperCase();
}

export function getGenderName(genderSegment) {
  return (genderSegment) ? (genderSegment.toLowerCase() === 'b' ? 'Boys' : (genderSegment.toLowerCase() === 'g' ? 'Girls' : 'Coed')) : '';
}

export function getLevelName(levels, levelSegment) {
  const levelItems = (levels && levels.length > 0) ? levels.filter((l) => (l.shortLevel && levelSegment) ? l.shortLevel.toLowerCase() === levelSegment.toLowerCase() : false) : [];
  return (levelItems.length > 0) ? levelItems[0].levelName : 'All';
};

export function ucwords(words) {
  let str = words.toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
  	function(s){
  	  return s.toUpperCase();
	});
};

export function urlParamToTitle(param) {
  return ucwords(param.replace('-', ' '));
}

export function nth(n) {
  return["st","nd","rd"][((n+90)%100-10)%10-1]||"th";
}

export function getColorVariation(col, amt) {
  var usePound = true;
  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }
  var num = parseInt(col,16);
  var r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if  (r < 0) r = 0;
  var b = ((num >> 8) & 0x00FF) + amt;
  if (b > 255) b = 255;
  else if  (b < 0) b = 0;
  var g = (num & 0x0000FF) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}

export function createAdUnit( { adType, adId, adUnitPath } ) {
  
  if ( window.didna && window.didna.cmd ) {
    // Check to see if the ad has already been registered
    if ( window?.didna?._adUnits && window.didna._adUnits.length > 0 && [...window.didna._adUnits].filter(function(adUnit) { 
      return (adUnit[0] === adId);
    }).length > 0 ) {
      // Ad is already registered, skipping.
    } else {

      let adUnit = {
        id: adId,
        adUnitPath: `${adUnitPath}/${adId}`,
        targeting: { 'auid': adId } 
      };

      /*
       * Set the available sizes based on ad type/position
       */
      if ( adType === 'leaderboard' ) {

        adUnit.size =  [[728, 90], [300,250], [250,250], [320, 50], [300, 50]];

        let mediumScreen = [[ [1265, 0], [[728, 90]] ]]; /* At most 1265 wide */
        let smallScreen  = [];

        if (adId === 'midpage-leaderboard') {
          smallScreen = [[ [320, 0], [[300, 250], [250, 250]] ]]; /* At most 320px wide */
        } else {
          smallScreen  = [[ [320, 0], [[320, 50], [300, 50]] ]]; /* At most 320px wide */
        }       

        adUnit.sizeMap = [...mediumScreen, ...smallScreen];

      } else if ( adType === 'rail' ) {

        adUnit.size = [[300, 250], [250, 250]];

      }

      window.didna.cmd.push( function() {
        window.didna.createAdUnits( adUnit );
      });

      // if(adUnit.id === "right-rail") {
      //   const didnaConfig = window.DIDNA_CONFIG.pageTargeting
      //   console.log("DIDNA Config")
      //   console.log(didnaConfig)
      //   console.log("window.didna")
      //   console.log(window.didna)
      //   console.log("window.googletag")
      //   console.log(window.googletag)
      // }
    }
  }
}

export function removeAdUnit( adId ) {
  if ( window.didna && window.didna.cmd ) {
    window.didna.cmd.push( function() {
      window.didna.removeAdUnits( adId );
    });
  }
}

/**
 * Update google tag data
 * @param {*} param0 
 */
export function setSchoolParametersForGoogleTag( { schoolId, schoolName, city, state, zip, sport, level, gender } ) {
    if(schoolName) {
      var DIDNA_CONFIG = {
        "pageTargeting": {
            "WebFolder":  (schoolId && schoolId.length > 0) ? schoolId : '',
            "school": (schoolName && schoolName.length > 0) ? schoolName : '',
            "City": (city && city.length > 0) ? city : '',
            "City2": (city && city.length > 0) ? city : '',
            "State":  (state && state.length > 0) ? state : '',
            "zip": (zip && zip.length > 0) ? zip : '',
            "sport": (sport && sport.length > 0) ? sport : 'non-sport',
            "Level":  (level && level.length > 0) ? level : '',
            "gender":  (gender && gender.length > 0) ? gender : '',
        }
    };

    window.DIDNA_CONFIG = DIDNA_CONFIG;
    console.log("Page PKV Loaded")
    console.log(DIDNA_CONFIG)
    var script = document.createElement("script");
    script.src = "https://storage.googleapis.com/didna_hb/cnxt_digital_/8to18/didna_config.js";
    document.head.appendChild(script);
    console.log("Config Load");
    console.log("https://storage.googleapis.com/didna_hb/cnxt_digital_/8to18/didna_config.js");
  }
}

export function createInterstitialAdUnit(adId)
{

  if ( window.googletag && window.googletag.cmd ) {

    let interstitialSlot;

    window.googletag.cmd.push(function() {

      // Define a web interstitial ad slot.
      interstitialSlot = window.googletag.defineOutOfPageSlot(adId, window.googletag.enums.OutOfPageFormat.INTERSTITIAL);

      // Slot returns null if the page or device does not support interstitials.
      if (interstitialSlot) {

        interstitialSlot.addService(window.googletag.pubads());

        document.getElementById('interstitialAdStatus').style.background = 'rgb(255, 193, 7)';
        document.getElementById('interstitialAdStatus').innerText = 'Interstitial is loading...';

        // Add event listener to enable navigation once the interstitial loads.
        // If this event doesn't fire, try clearing local storage and refreshing
        // the page.
        window.googletag.pubads().addEventListener('slotOnload', function(event) {
          if (interstitialSlot === event.slot) {
            document.getElementById('interstitialTestLink').style.display = 'block';
            document.getElementById('interstitialAdStatus').style.background = '#4caf50';
            document.getElementById('interstitialAdStatus').innerText = 'Interstitial is loaded.';
          }
        });

        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
        window.googletag.display(interstitialSlot);

      }
    });
  }
}

/*
 * Return the name of the current page, lowercase.
 */
export function getPageName()
{
  let paths = window.location.pathname.split('/');
  return (paths[2]) ? paths[2].toLowerCase() : null;
}

/**
 * 
 * Generate a full schedule URL based on provided params
 * 
 * @param {*} param0 
 * @returns 
 */
export function getFullScheduleUrl( { sportSegment, genderSegment, levelSegment, schoolId } ) {
  let url = `/${schoolId}/`;
  if ( sportSegment && genderSegment && levelSegment ) {
    url += `schedules/${sportSegment}/${genderSegment}/${levelSegment}/`;
  } else {
    url += 'calendar';
  }
  return url;
}

export function getDailyCalendarUrl( schoolId ) {
  let url = `/${schoolId}/`
  return url += 'daily-calendar';
}

export function getMonthlyCalendarUrl( schoolId ) {
  let url = `/${schoolId}/`
  return url += 'calendar';
}

export function getPageUrl( type, sportSegment, genderSegment, levelSegment, schoolId ) {
  let url = `/${schoolId}/${type}`;
  if ( sportSegment ) {
    url += `/${sportSegment}`;
  }
  if ( genderSegment ) {
    url += `/${genderSegment}`;
  }
  if ( levelSegment ) {
    url += `/${levelSegment}`;
  }
  return url;
}

export function setTitleDescByState(state) {
  const stateUpper = state.toUpperCase();
  const getState = getStateName(stateUpper)
  if(getState) {
    document.title = `Snap! Manage, ${getState} directory`
    return
  }
  document.title = `Snap! Manage`
}

function getStateName(acronym) {
  const data = {
    AZ: 'Arizona',
    AL: 'Alabama',
    AK: 'Alaska',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    AS: "American Samoa",
    GU: "Guam",
    MP: "Northern Mariana Islands",
    PR: "Puerto Rico",
    VI: "U.S. Virgin Islands",
    UM: "U.S. Minor Outlying Islands",
  }
  return data[acronym] ?? acronym
}

/**
 * Add custom campaign parameters to your URLs.
 *
 * @param {String} destinationUrl - Original URL
 * @param {String} source
 * @param {String} medium
 * @param {String} campaign
 */
export function generateUTMURL(destinationUrl, source, medium, campaign) {
  if (!destinationUrl) {
    throw new Error('Destination URL can not be empty.');
  }
  if (!source) {
   throw new Error('Source can not be empty.');
  }
  if (!medium) {
   throw new Error('Medium can not be empty.');
  }
  if (!campaign) {
   throw new Error('Campaign can not be empty.');
  }

  let url; 

  try {
    url = new URL(destinationUrl);
    
    if (!url.searchParams.has('utm_source')) {
      url.searchParams.set('utm_source', source);
    }
    if (!url.searchParams.has('utm_medium')) {
      url.searchParams.set('utm_medium', medium);
    }
    if (!url.searchParams.has('utm_campaign')) {
      url.searchParams.set('utm_campaign', campaign);
    }
  
    return url.href;
  } catch (error) {
    console.log(error)
    return "#";
  } 
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export function getState() {
  let state;
  if(isLocalhost) {
    state = (process.env.REACT_APP_DEFAULT_STATE) ? process.env.REACT_APP_DEFAULT_STATE : 'il';
  }
  return {state: state.toUpperCase(), stateName: getStateName(state.toUpperCase())};
}