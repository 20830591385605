import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import rootSaga from './rootSaga';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import menuReducer from './menu/menu.reducer';
import activitiesReducer from './activities/activities.reducer';
import headerReducer from './header/header.reducer';
import calendarReducer from './calendar/calendar.reducer';
import mainReducer from './main/main.reducer';
import quickReducer from './quick/quick.reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const history = createBrowserHistory();

const reducersAll = combineReducers({
    menu: menuReducer,
    main: mainReducer,
    activities: activitiesReducer,
    header: headerReducer,
    quick: quickReducer,
    calendar: calendarReducer
});

const store = createStore(
	reducersAll,
	composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export { store, history };