import * as t from './calendar.types';

export const getEventDetail = (eventId, changeTitle) => ({
    type: t.GET_EVENT_DETAIL,
    payload: {
        eventId, changeTitle
    }
});

export const getCalendar = (month, year, team, sport, facility, eventTypeFilters, place) => ({
    type: t.GET_CALENDAR,
    payload: {
        month, year, team, sport, facility, eventTypeFilters, place
    }
});

export const selectSportsActivities = (element) => ({
    type: t.SELECT_SPORTS_ACTIVITIES,
    payload: element
});
export const selectTeamsGroups = (element) => ({
    type: t.SELECT_TEAMS_GROUPS,
    payload: element
});
export const selectFacilities = (element) => ({
    type: t.SELECT_FACILITIES,
    payload: element
});



export const monthHandler = () => ({
    type: t.MONTH_HANDLER
});

export const getEventDetailResultSuccess = (response, changeTitle) => ({
    type: t.GET_EVENT_DETAIL_RESULT_SUCCESS,
    payload: { response, changeTitle }
});

export const getEventDetailResultError = (response) => ({
    type: t.GET_EVENT_DETAIL_RESULT_ERROR,
    payload: response.message
});

export const getCalendarResultSuccess = (response) => ({
    type: t.GET_CALENDAR_RESULT_SUCCESS,
    payload: response
});

export const getCalendarResultError = (response) => ({
    type: t.GET_CALENDAR_RESULT_ERROR,
    payload: response.message
});

export const showCalendarMore = (events) => ({
    type: t.SHOW_CALENDAR_MORE,
    payload: {
        events
    }
});

export const closeCalendarMore = () => ({
    type: t.CLOSE_CALENDAR_MORE
});
    