export const SPECIFY_TITLE = 'SPECIFY_TITLE';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITIES_RESULT_SUCCESS = 'GET_ACTIVITIES_RESULT_SUCCESS';
export const GET_EVENT_DETAIL_RESULT_SUCCESS = 'GET_EVENT_DETAIL_RESULT_SUCCESS';
export const GET_NEW_DETAIL_RESULT_SUCCESS = 'GET_NEW_DETAIL_RESULT_SUCCESS';
export const GET_SCHEDULES_RESULT_SUCCESS = 'GET_SCHEDULES_RESULT_SUCCESS';
export const GET_ROSTERS_RESULT_SUCCESS = 'GET_ROSTERS_RESULT_SUCCESS';
export const GET_NEWS_RESULT_SUCCESS = 'GET_NEWS_RESULT_SUCCESS';
export const GET_GALLERY_RESULT_SUCCESS = 'GET_GALLERY_RESULT_SUCCESS';
export const GET_MENU_STATE_SCHOOL_RESULT_SUCCESS = 'GET_MENU_STATE_SCHOOL_RESULT_SUCCESS';
export const GET_EVENT_DIRECTIONS = 'GET_EVENT_DIRECTIONS';
export const GET_STATIC = 'GET_STATIC';
export const GET_MENU_STATE_SCHOOL_RESULT_ERROR = 'GET_MENU_STATE_SCHOOL_RESULT_ERROR';