import { all, takeEvery, call, put } from 'redux-saga/effects'
import * as actionsTypes from "./menu.types";
import * as actions from "./menu.actions";
import { apiService } from "../../services/api";

export function* getMenuStateSchool(action) {
	yield takeEvery(actionsTypes.SPECIFY_URL, function* (action) {
		try {
			const getMenuStateSchoolResult = yield call(apiService.getMenu, action.payload);
			if(getMenuStateSchoolResult && !getMenuStateSchoolResult.status) {
				yield put(actions.getMenuStateSchoolResultSuccess(getMenuStateSchoolResult));
			} else {
				yield put(actions.getMenuStateSchoolResultError(getMenuStateSchoolResult));
			}
		} catch (error) {
			yield put(actions.getMenuStateSchoolResultError(error));
		}
	});
}

export function* getStatic(action) {
	yield takeEvery(actionsTypes.GET_STATIC, function* (action) {
		try {
			const getStaticResult = yield call(apiService.getStatic, action.payload);
			if(getStaticResult.pageDetails) {
				yield put(actions.getStaticResultSuccess(getStaticResult.pageDetails, action.payload));
			} else {
				yield put(actions.getStaticResultError(getStaticResult));
			}
		} catch (error) {
			yield put(actions.getStaticResultError("error", error));
		}
	});
}

export function* getEventDirections(action) {
	yield takeEvery(actionsTypes.GET_EVENT_DIRECTIONS, function* (action) {
		try {
			const getEventDirectionsResult = yield call(apiService.getEventDirections, action.payload);
			if(getEventDirectionsResult.directions) {
				yield put(actions.getEventDirectionsResultSuccess(getEventDirectionsResult.directions, action.payload));
			} else {
				yield put(actions.getEventDirectionsResultError(getEventDirectionsResult));
			}
		} catch (error) {
			yield put(actions.getEventDirectionsResultError("error", error));
		}
	});
}

export function* getNewDetail(action) {
	yield takeEvery(actionsTypes.GET_NEW_DETAIL, function* (action) {
		try {
			const getNewDetailResult = yield call(apiService.getNewDetail, action.payload);
			if(getNewDetailResult.announcement) {
				yield put(actions.getNewDetailResultSuccess(getNewDetailResult.announcement, action.payload.changeTitle));
			} else {
				yield put(actions.getNewDetailResultError(getNewDetailResult));
			}
		} catch (error) {
			yield put(actions.getNewDetailResultError(error));
		}
	});
}
export default function* rootSaga() {
  yield all([
  	getMenuStateSchool(),
  	getStatic(),
  	getNewDetail(),
  	getEventDirections()
  ]);
}