import React from 'react';
import { Provider } from "react-redux";
import AppRoutes from "./app.routes";
import { store, history } from './app/store';
import _ from 'lodash';
import { HelmetProvider } from 'react-helmet-async';

if ( window.innerWidth >= 1024 ) {
	document.body.classList.add("menu-opened");
}

// need to handle any link coming in with hf, not just the home url
let pathArray = window.location.pathname.split("/");
let school = pathArray[1].toLowerCase();

// unfortunately the activities and athletics urls are not the same between the old and new site
// so we just need to send to the old site for now
let hardcodedSchools = ['easths'];

if (_.includes(hardcodedSchools, school)) {
		window.location.replace("https://prod-web-alb.8to18.com/" + school);
}

const helmetContext = {};

const App = () => (
	<HelmetProvider context={helmetContext}>
		<Provider store={store}>
			<AppRoutes history={history} />
		</Provider>
	</HelmetProvider>
);

export default App;
