import * as t from './calendar.types';
import { trGOne } from '../common';
const INITIAL_STATE = {
    eventDetailData: null,
    calendarData: null,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    displayMonthSelector: false,
    calendarMore: false,
    filteredTeamsGroupsOptions: [],
    selectedTeamsGroups: null,
    selectedSportsGroups: null,
    selectedFacilities: null,
    selectedLocation: null,
    errorMessage: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case t.SHOW_CALENDAR_MORE:
            return {
                ...state, calendarMore: action.payload.events.events.length ? action.payload.events : false
            };
        case t.CLOSE_CALENDAR_MORE:
            return {
                ...state, calendarMore: false
            };
        case t.MONTH_HANDLER:
            return {
                ...state, displayMonthSelector: true
            };
        case t.GET_EVENT_DETAIL_RESULT_ERROR:
            return {
                ...state, eventDetailData: null
            };
        case t.GET_EVENT_DETAIL_RESULT_SUCCESS:
            return {
                ...state, eventDetailData: action.payload.response
            };

        case t.GET_CALENDAR_RESULT_SUCCESS:
            return {
                ...state, 
                calendarData: action.payload
            };
        case t.GET_CALENDAR:
            return {
                ...state,
                calendarData: null,
                displayMonthSelector: false,
                year: action.payload.year,
                month: action.payload.month,
                selectedTeamsGroups: action.payload.team,
                selectedSportsGroups: action.payload.sport,
                selectedFacilities: action.payload.facility,
                selectedLocation: action.payload.place
            };
        case t.GET_CALENDAR_RESULT_ERROR:
            return {
                ...state,
                calendarData: null,
                errorMessage: action.payload.message
            };
        case t.SELECT_SPORTS_ACTIVITIES:
            let filteredTeamsGroupsOptions = [];
            if(action.payload) {
                [...[action.payload]].forEach((item) => {
                    item.levels.forEach((levelItem) => {
                        let foundLevel = false;
                        filteredTeamsGroupsOptions.forEach((levelInnerItem) => {
                            if(
                                levelInnerItem.levelName === levelItem.levelName &&
                                levelInnerItem.gender === levelItem.gender &&
                                levelInnerItem.sport
                            ) {
                                foundLevel = true;
                            }
                        });
                        if(!foundLevel) {
                            filteredTeamsGroupsOptions.push(
                                Object.assign(
                                    levelItem,
                                    {
                                        gender: item.gender,
                                        sport: item.sport
                                    }
                                )
                            );
                        }
                    });
                });
                filteredTeamsGroupsOptions = filteredTeamsGroupsOptions.map((item) => {
                    return Object.assign(item, {
                        title: ((item.gender && item.gender !== 'N' ? trGOne(item.gender) + ' ' : '') + (item.levelName ? item.levelName + ' ' : '') + item.sport)
                    });
                });
                filteredTeamsGroupsOptions.sort(function(a, b){
                    if(a.title < b.title) { return -1; }
                    if(a.title > b.title) { return 1; }
                    return 0;
                });
            }
            return {
                ...state,
                filteredTeamsGroupsOptions: filteredTeamsGroupsOptions
            };
        default: return state;
    }
};

export default reducer;