import { all, takeEvery, call, put } from 'redux-saga/effects'
import * as actionsTypes from "./activities.types";
import * as actions from "./activities.actions";
import { apiService } from "../../services/api";

export function* getActivities(action) {
	yield takeEvery(actionsTypes.GET_ACTIVITIES, function* (action) {
		try {
			const getActivitiesResult = yield call(apiService.getActivities, action.payload);
			if(!getActivitiesResult.message) {
				yield put(actions.getActivitiesResultSuccess(getActivitiesResult));
			} else {
				yield put(actions.getActivitiesResultError(getActivitiesResult));
			}
		} catch (error) {
			yield put(actions.getActivitiesResultError(error));
		}
	});
}

export function* getSchedules(action) {
	yield takeEvery(actionsTypes.GET_SCHEDULES, function* (action) {
		try {
			const getSchedulesResult = yield call(apiService.getSchedules, action.payload);
			if(!getSchedulesResult.message) {
				yield put(actions.getSchedulesResultSuccess(getSchedulesResult));
			} else {
				yield put(actions.getSchedulesResultError(getSchedulesResult));
			}
		} catch (error) {
			yield put(actions.getSchedulesResultError(error));
		}
	});
}

export function* getRosters(action) {
	yield takeEvery(actionsTypes.GET_ROSTERS, function* (action) {
		try {
			const getRostersResult = yield call(apiService.getRosters, action.payload);
			if(!getRostersResult.message) {
				yield put(actions.getRostersResultSuccess(getRostersResult, action.payload.fromHome));
			} else {
				yield put(actions.getRostersResultError(getRostersResult));
			}
		} catch (error) {
			yield put(actions.getRostersResultError(error));
		}
	});
}

export function* getNews(action) {
	yield takeEvery(actionsTypes.GET_NEWS, function* (action) {
		try {
			const getNewsResult = yield call(apiService.getNews, action.payload);
			if(!getNewsResult.message) {
				yield put(actions.getNewsResultSuccess(getNewsResult));
			} else {
				yield put(actions.getNewsResultError(getNewsResult));
			}
		} catch (error) {
			yield put(actions.getNewsResultError(error));
		}
	});
}

export function* getGallery(action) {
	yield takeEvery(actionsTypes.GET_GALLERY, function* (action) {
		try {
			const getGalleryResult = yield call(apiService.getGallery, action.payload);
			if(!getGalleryResult.message) {
				yield put(actions.getGalleryResultSuccess(getGalleryResult));
			} else {
				yield put(actions.getGalleryResultError(getGalleryResult));
			}
		} catch (error) {
			yield put(actions.getGalleryResultError(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
  	getActivities(),
  	getSchedules(),
  	getNews(),
  	getGallery(),
  	getRosters()
  ]);
}