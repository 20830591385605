export const SPECIFY_URL = 'SPECIFY_URL';

export const SWITCH_MENU = 'SWITCH_MENU';
export const CLOSE_INNER_MENU = 'CLOSE_INNER_MENU';
export const CLOSE_INNER_INNER_MENU = 'CLOSE_INNER_INNER_MENU';
export const CLOSE_INNER_INNER_INNER_MENU = 'CLOSE_INNER_INNER_INNER_MENU';
export const OPEN_INNER_MENU = 'OPEN_INNER_MENU';
export const OPEN_INNER_INNER_MENU = 'OPEN_INNER_INNER_MENU';
export const OPEN_INNER_INNER_INNER_MENU = 'OPEN_INNER_INNER_INNER_MENU';

export const GET_MENU_STATE_SCHOOL_RESULT_SUCCESS = 'GET_MENU_STATE_SCHOOL_RESULT_SUCCESS';
export const GET_MENU_STATE_SCHOOL_RESULT_ERROR = 'GET_MENU_STATE_SCHOOL_RESULT_ERROR';

export const GET_STATIC = 'GET_STATIC';
export const GET_STATIC_RESULT_SUCCESS = 'GET_STATIC_RESULT_SUCCESS';
export const GET_STATIC_RESULT_ERROR = 'GET_STATIC_RESULT_ERROR';

export const GET_NEW_DETAIL = 'GET_NEW_DETAIL';
export const GET_NEW_DETAIL_RESULT_SUCCESS = 'GET_NEW_DETAIL_RESULT_SUCCESS';
export const GET_NEW_DETAIL_RESULT_ERROR = 'GET_NEW_DETAIL_RESULT_ERROR';

export const GET_EVENT_DIRECTIONS = 'GET_EVENT_DIRECTIONS';
export const GET_EVENT_DIRECTIONS_RESULT_SUCCESS = 'GET_EVENT_DIRECTIONS_RESULT_SUCCESS';
export const GET_EVENT_DIRECTIONS_RESULT_ERROR = 'GET_EVENT_DIRECTIONS_RESULT_ERROR';

export const CLOSE_URGENT = 'CLOSE_URGENT';
export const SET_VIEWED_URGENT_ANNOUNCEMENTS = 'SET_VIEWED_URGENT_ANNOUNCEMENTS';
export const SET_VIEWED_INTERSTITIAL_AD = 'SET_VIEWED_INTERSTITIAL_AD';