import * as t from './main.types';

const INITIAL_STATE = {
    loading: 0,
    menuDrawerStatus: (window.innerWidth >= 1024) ? 'opened' : 'closed',
    schoolsData: []
};
const reducer = (state = INITIAL_STATE, action) => {
    let {loading} = state;
    switch (action.type) {
        case t.MENU_DRAWER_STATUS:
            const newStatus = (state.menuDrawerStatus === 'closed') ? 'opened' : 'closed';
            if ( newStatus === 'opened') {
                document.body.classList.add('menu-opened');
            } else {
                document.body.classList.remove('menu-opened');
            } 
            return {
                ...state, menuDrawerStatus: newStatus
            }
        case t.SPECIFY_URL:
        case t.GET_SCHOOLS:
        case t.GET_ACTIVITIES:
        case t.GET_SCHEDULES:
        case t.GET_ROSTERS:
        case t.GET_NEWS:
        case t.GET_GALLERY:
        case t.GET_EVENT_DETAIL:
        case t.GET_NEW_DETAIL:
        case t.GET_CALENDAR:
        case t.GET_STATIC:
        case t.GET_EVENT_DIRECTIONS:
            loading++;
            return {
                ...state, loading: loading
            };
        case t.GET_EVENT_DIRECTIONS_RESULT_SUCCESS:
        case t.GET_SCHEDULES_RESULT_SUCCESS:
        case t.GET_ROSTERS_RESULT_SUCCESS:
        case t.GET_NEWS_RESULT_SUCCESS:
        case t.GET_STATIC_RESULT_SUCCESS:
        case t.GET_MENU_STATE_SCHOOL_RESULT_SUCCESS:
        case t.GET_ACTIVITIES_RESULT_SUCCESS:
        case t.GET_EVENT_DETAIL_RESULT_SUCCESS:
        case t.GET_NEW_DETAIL_RESULT_SUCCESS:
        case t.GET_CALENDAR_RESULT_SUCCESS:
        case t.GET_GALLERY_RESULT_SUCCESS:
            loading--;
            return {
                ...state, loading: loading, error: false
            };
        case t.GET_SCHOOLS_RESULT_SUCCESS:
            loading--;
            return {
                ...state, loading: loading, schoolsData: action.payload
            };
        case t.GET_EVENT_DIRECTIONS_RESULT_ERROR:
        case t.GET_SCHEDULES_RESULT_ERROR:
        case t.GET_ROSTERS_RESULT_ERROR:
        case t.GET_NEWS_RESULT_ERROR:
        case t.GET_STATIC_RESULT_ERROR:
        case t.GET_MENU_STATE_SCHOOL_RESULT_ERROR:
        case t.GET_ACTIVITIES_RESULT_ERROR:
        case t.GET_EVENT_DETAIL_RESULT_ERROR:
        case t.GET_NEW_DETAIL_RESULT_ERROR:
        case t.GET_CALENDAR_RESULT_ERROR:
        case t.GET_GALLERY_RESULT_ERROR:
        case t.GET_SCHOOLS_RESULT_ERROR:
            loading--;
            return {
                ...state, loading: loading, error: true
            };
        default: return state;
    }
};

export default reducer;