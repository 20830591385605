export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITIES_RESULT_SUCCESS = 'GET_ACTIVITIES_RESULT_SUCCESS';
export const GET_ACTIVITIES_RESULT_ERROR = 'GET_ACTIVITIES_RESULT_ERROR';

export const GET_SCHEDULES = 'GET_SCHEDULES';
export const GET_SCHEDULES_RESULT_SUCCESS = 'GET_SCHEDULES_RESULT_SUCCESS';
export const GET_SCHEDULES_RESULT_ERROR = 'GET_SCHEDULES_RESULT_ERROR';

export const GET_ROSTERS = 'GET_ROSTERS';
export const GET_ROSTERS_RESULT_SUCCESS = 'GET_ROSTERS_RESULT_SUCCESS';
export const GET_ROSTERS_RESULT_ERROR = 'GET_ROSTERS_RESULT_ERROR';

export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_RESULT_SUCCESS = 'GET_NEWS_RESULT_SUCCESS';
export const GET_NEWS_RESULT_ERROR = 'GET_NEWS_RESULT_ERROR';

export const GET_GALLERY = 'GET_GALLERY';
export const GET_GALLERY_RESULT_SUCCESS = 'GET_GALLERY_RESULT_SUCCESS';
export const GET_GALLERY_RESULT_ERROR = 'GET_GALLERY_RESULT_ERROR';