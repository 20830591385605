import { all, takeEvery, call, put } from 'redux-saga/effects'
import * as actionsTypes from "./calendar.types";
import * as actions from "./calendar.actions";
import { apiService } from "../../services/api";

export function* getEventDetail(action) {
	yield takeEvery(actionsTypes.GET_EVENT_DETAIL, function* (action) {
		try {
			const getEventDetailResult = yield call(apiService.getEventDetail, action.payload);
			if(getEventDetailResult.eventDetails) {
				yield put(actions.getEventDetailResultSuccess(getEventDetailResult.eventDetails, action.payload.changeTitle));
			} else {
				yield put(actions.getEventDetailResultError(getEventDetailResult));
			}
		} catch (error) {
			yield put(actions.getEventDetailResultError(error));
		}
	});
}

export function* getCalendar(action) {
	yield takeEvery(actionsTypes.GET_CALENDAR, function* (action) {
		try {
			const getCalendarResult = yield call(apiService.getCalendar, action.payload);
			if(getCalendarResult.calendarDate) {
				yield put(actions.getCalendarResultSuccess(getCalendarResult));
			} else {
				yield put(actions.getCalendarResultError(getCalendarResult));
			}
		} catch (error) {
			yield put(actions.getCalendarResultError(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
  	getEventDetail(),
  	getCalendar()
  ]);
}