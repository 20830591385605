import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import MainRedirectToNewSite from "./components_v2/MainRedirectToNewSite";
// import Main from "./components_v2/Main";
import ScrollToTop from "./components_v2/wrappers/ScrollToTop";
// import SelectSchoolPage from "./pages/selectSchool/selectSchool.page";
import SelectSchoolPage from "./pages/selectSchool/selectSchoolRedirect.page";
import Print from "./pages/print";
import "./App.scss";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const getState = () => {
  if (isLocalhost) {
    return process.env.REACT_APP_DEFAULT_STATE
      ? process.env.REACT_APP_DEFAULT_STATE
      : "il";
  }
  return window.location.hostname.split(".")[0];
};
const reload = () => window.location.reload();

const sitemapPath = `/sitemap/${getState()}/sitemap.txt`;

const AppRoutes = ({ history }) => {
  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        <Route path={sitemapPath} onEnter={reload} />
        <Route
          path="/:schoolId/:activities/:sportSegment/:genderSegment/:levelSegment/print"
          component={Print}
        />
        <Route path="/:schoolId">
          <MainRedirectToNewSite />
          {/* <Main /> */}
        </Route>
        <Route path="/" component={SelectSchoolPage} />
      </Switch>
    </Router>
  );
};
const mapStateToProps = (state) => {
  return {
    menuState: state.menu.menuState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
