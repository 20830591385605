import { all } from "redux-saga/effects";

import sagas from "./sagas";

export default function* rootSaga(getState) {
	yield all([
		sagas.mainSaga(),
		sagas.menuSaga(),
		sagas.quickSaga(),
		sagas.activitiesSaga(),
		sagas.calendarSaga()
	]);
}
