export const MENU_DRAWER_STATUS = 'MENU_DRAWER_STATUS';

export const SPECIFY_URL = 'SPECIFY_URL';
export const GET_MENU_STATE_SCHOOL_RESULT_SUCCESS = 'GET_MENU_STATE_SCHOOL_RESULT_SUCCESS';
export const GET_MENU_STATE_SCHOOL_RESULT_ERROR = 'GET_MENU_STATE_SCHOOL_RESULT_ERROR';

export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITIES_RESULT_SUCCESS = 'GET_ACTIVITIES_RESULT_SUCCESS';
export const GET_ACTIVITIES_RESULT_ERROR = 'GET_ACTIVITIES_RESULT_ERROR';

export const GET_EVENT_DETAIL = 'GET_EVENT_DETAIL';
export const GET_EVENT_DETAIL_RESULT_SUCCESS = 'GET_EVENT_DETAIL_RESULT_SUCCESS';
export const GET_EVENT_DETAIL_RESULT_ERROR = 'GET_EVENT_DETAIL_RESULT_ERROR';

export const GET_NEW_DETAIL = 'GET_NEW_DETAIL';
export const GET_NEW_DETAIL_RESULT_SUCCESS = 'GET_NEW_DETAIL_RESULT_SUCCESS';
export const GET_NEW_DETAIL_RESULT_ERROR = 'GET_NEW_DETAIL_RESULT_ERROR';

export const GET_CALENDAR = 'GET_CALENDAR';
export const GET_CALENDAR_RESULT_SUCCESS = 'GET_CALENDAR_RESULT_SUCCESS';
export const GET_CALENDAR_RESULT_ERROR = 'GET_CALENDAR_RESULT_ERROR';

export const GET_SCHOOLS = 'GET_SCHOOLS';
export const GET_SCHOOLS_RESULT_SUCCESS = 'GET_SCHOOLS_RESULT_SUCCESS';
export const GET_SCHOOLS_RESULT_ERROR = 'GET_SCHOOLS_RESULT_ERROR';

export const GET_STATIC = 'GET_STATIC';
export const GET_STATIC_RESULT_SUCCESS = 'GET_STATIC_RESULT_SUCCESS';
export const GET_STATIC_RESULT_ERROR = 'GET_STATIC_RESULT_ERROR';

export const GET_SCHEDULES = 'GET_SCHEDULES';
export const GET_SCHEDULES_RESULT_SUCCESS = 'GET_SCHEDULES_RESULT_SUCCESS';
export const GET_SCHEDULES_RESULT_ERROR = 'GET_SCHEDULES_RESULT_ERROR';

export const GET_ROSTERS = 'GET_ROSTERS';
export const GET_ROSTERS_RESULT_SUCCESS = 'GET_ROSTERS_RESULT_SUCCESS';
export const GET_ROSTERS_RESULT_ERROR = 'GET_ROSTERS_RESULT_ERROR';

export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_RESULT_SUCCESS = 'GET_NEWS_RESULT_SUCCESS';
export const GET_NEWS_RESULT_ERROR = 'GET_NEWS_RESULT_ERROR';

export const GET_GALLERY = 'GET_GALLERY';
export const GET_GALLERY_RESULT_SUCCESS = 'GET_GALLERY_RESULT_SUCCESS';
export const GET_GALLERY_RESULT_ERROR = 'GET_GALLERY_RESULT_ERROR';

export const GET_EVENT_DIRECTIONS = 'GET_EVENT_DIRECTIONS';
export const GET_EVENT_DIRECTIONS_RESULT_SUCCESS = 'GET_EVENT_DIRECTIONS_RESULT_SUCCESS';
export const GET_EVENT_DIRECTIONS_RESULT_ERROR = 'GET_EVENT_DIRECTIONS_RESULT_ERROR';