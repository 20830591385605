export const MONTH_HANDLER = 'MONTH_HANDLER';
export const GET_EVENT_DETAIL = 'GET_EVENT_DETAIL';
export const GET_EVENT_DETAIL_RESULT_SUCCESS = 'GET_EVENT_DETAIL_RESULT_SUCCESS';
export const GET_EVENT_DETAIL_RESULT_ERROR = 'GET_EVENT_DETAIL_RESULT_ERROR';

export const SELECT_SPORTS_ACTIVITIES = 'SELECT_SPORTS_ACTIVITIES';
export const SELECT_TEAMS_GROUPS = 'SELECT_TEAMS_GROUPS';
export const SELECT_FACILITIES = 'SELECT_FACILITIES';

export const GET_CALENDAR = 'GET_CALENDAR';
export const GET_CALENDAR_RESULT_SUCCESS = 'GET_CALENDAR_RESULT_SUCCESS';
export const GET_CALENDAR_RESULT_ERROR = 'GET_CALENDAR_RESULT_ERROR';

export const SHOW_CALENDAR_MORE = 'SHOW_CALENDAR_MORE';
export const CLOSE_CALENDAR_MORE = 'CLOSE_CALENDAR_MORE';