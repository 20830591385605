const apiUrl = process.env.REACT_APP_API_BASE_URL;

let headers = {
    'X-STATE': '',
    'X-SCHOOL': '',
}
function getMenu(payload) {
    headers = {
        'X-STATE': payload.state,
        'X-SCHOOL': payload.school
    };
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + '/api/school', requestOptions)
    .then(response => {
        //TODO handle error ?
        return response.json();
    })
    .then(res => res);
}
function getActivities(payload) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/activities/${payload.sportSegment}/${payload.genderSegment}/${payload.levelSegment}?year=${payload.year}`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}
function getSchedules(payload) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/activities/schedule/${payload.sportSegment}/${payload.genderSegment}/${payload.levelSegment}?year=${payload.year.value}`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}
function getRosters(payload) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/activities/roster/${payload.sportSegment}/${payload.genderSegment}/${payload.levelSegment}?year=${payload.year.value}`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}
function getNews(payload) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/activities/news/${payload.sportSegment}/${payload.genderSegment}/${payload.levelSegment}`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}
function getGallery(payload) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/activities/gallery/${payload.sportSegment}/${payload.genderSegment}/${payload.levelSegment}`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}

function getEventDetail(payload) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/calendar/details/${payload.eventId}`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}
function getNewDetail(payload) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/news/${payload.subpath}/${payload.newId}`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}
function getCalendar(payload) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/calendar/main?month=${payload.month + 1}&year=${payload.year}` + (payload.sport ? `&sport=${payload.sport.value}` : `&sport=all`) + `&place=${payload.place}` + (payload.team ? `&team=${payload.team.value}` : `&team=all`) + (payload.facility ? `&facility=${payload.facility.value}` : '&facility=all') + (payload.eventTypeFilters.length ? `&eventTypeFilters=${payload.eventTypeFilters.join(',')}` : ''), requestOptions)
    .then(response => response.json())
    .then(res => res);
}

function getSchools(payload) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'X-STATE': payload.state
        }
    };
    return fetch(apiUrl + `/api/home`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}
function getStatic(pagename) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/school/${pagename}`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}
function getEventDirections() {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(apiUrl + `/api/school/eventdirections`, requestOptions)
    .then(response => response.json())
    .then(res => res);
}


function getQuick(payload) {
    headers = {
        'X-STATE': payload.state,
        'X-SCHOOL': payload.school
    };
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    return fetch(apiUrl + '/api/school/quick', requestOptions)
    .then(response => {
        return response.json();
    })
}

export const apiService = {
    getMenu,
    getSchools,
    getEventDetail,
    getNewDetail,
    getCalendar,
    getActivities,
    getStatic,
    getSchedules,
    getGallery,
    getNews,
    getRosters,
    getEventDirections,
    getQuick
};