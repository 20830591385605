import * as t from './activities.types';
import dayjs from 'dayjs';

const currentYear = dayjs().year();
const startYear = (dayjs().month() >= 5) ? dayjs().year() : dayjs().subtract(1, 'year').year();
const endYear = parseInt(startYear) + 1;

const INITIAL_STATE = {
    activitiesList: null,
    sportSegment: '',
    genderSegment: '',
    levelSegment: '',
    year: currentYear,
    scheduleYear: {
        value: startYear + '-' + endYear,
        label: startYear + '-' + endYear,
    },
    rosterYear: {
        value: startYear + '-' + endYear,
        label: startYear + '-' + endYear + ' Season',
    },
    years: [],
    events: [],
    rosterSectionsTeam: [],
    rosterSections: [],
    photos: [],
    announcements: []
};

const reducer = (state = INITIAL_STATE, action) => {

    if ( action.type === t.GET_SCHEDULES ) {
        console.log("action.payload : ", action.payload);
    }

    switch (action.type) {
        case t.GET_ACTIVITIES_RESULT_SUCCESS:
            return {
                ...state, activitiesList: action.payload
            };
        case t.GET_SCHEDULES_RESULT_SUCCESS:
            return {
                ...state, events: action.payload.events, years: action.payload.years
            };
        case t.GET_ROSTERS_RESULT_SUCCESS:
        if(action.payload.fromHome) {
            let rosterSectionsTeam = [];
            action.payload.response.rosterSections.forEach((rosterSection) => {
                rosterSection.roster.forEach((el) => {
                    rosterSectionsTeam.push(el);
                });
            });
            return {
                ...state, rosterSectionsTeam: rosterSectionsTeam, years: action.payload.response.years
            };
        } else {
            return {
                ...state, rosterSections: action.payload.response.rosterSections, years: action.payload.response.years
            };            
        }
        case t.GET_NEWS_RESULT_SUCCESS:
            return {
                ...state, announcements: action.payload.announcements
            };
        case t.GET_GALLERY_RESULT_SUCCESS:
            return {
                ...state, photos: action.payload.photos
            };
        case t.GET_ACTIVITIES:
            return {
                ...state,
                sportSegment: action.payload.sportSegment,
				genderSegment: action.payload.genderSegment,
				levelSegment: action.payload.levelSegment,
				year: action.payload.year
            };
        case t.GET_SCHEDULES:
            return {
                ...state,
                sportSegment: action.payload.sportSegment,
                genderSegment: action.payload.genderSegment,
                levelSegment: action.payload.levelSegment,
                scheduleYear: action.payload.year
            };
        case t.GET_ROSTERS:
            return {
                ...state,
                sportSegment: action.payload.sportSegment,
                genderSegment: action.payload.genderSegment,
                levelSegment: action.payload.levelSegment,
                rosterYear: action.payload.year
            };
        case t.GET_NEWS:
            return {
                ...state,
                sportSegment: action.payload.sportSegment,
                genderSegment: action.payload.genderSegment,
                levelSegment: action.payload.levelSegment
            };
        case t.GET_GALLERY:
            return {
                ...state,
                sportSegment: action.payload.sportSegment,
                genderSegment: action.payload.genderSegment,
                levelSegment: action.payload.levelSegment
            };
		case t.GET_ACTIVITIES_RESULT_ERROR:
            return {
                ...state, activitiesList: null
            };
        case t.GET_SCHEDULES_RESULT_ERROR:
            return {
                ...state, events: [], years: []
            };
        case t.GET_ROSTERS_RESULT_ERROR:
            return {
                ...state, rosterSectionsTeam: [], years: []
            };
        case t.GET_NEWS_RESULT_ERROR:
            return {
                ...state, announcements: []
            };
        case t.GET_GALLERY_RESULT_ERROR:
            return {
                ...state, photos: []
            };
                    
        default: return state;
    }
};

export default reducer;